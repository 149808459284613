import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Divider, Header, Loader, Message } from "semantic-ui-react";
import { EnvironmentStatus, useAuth0TokenOptions } from "../../../data";
import { GET_ENVIRONMENT_QUERY_KEY, useEnvironmentQuery } from "../../../query";
import { SEMANTIC_BLUE, SEMANTIC_GREY } from "../../../utils/colors";
import { reprovisionEnvironment } from "../data";

const isRetryable = (status?: string): boolean => {
    return (
        status === EnvironmentStatus.ERRORED ||
        status === EnvironmentStatus.CANCELLED ||
        status === EnvironmentStatus.DEPROVISIONED
    );
};

const provisioningStepCounterStyle = {
    marginRight: "10px",
    borderRadius: "50%",
    background: SEMANTIC_BLUE,
    width: "24px",
    height: "24px",
    color: "white",
    borderColor: SEMANTIC_BLUE,
};

const ProvisioningStepCounter = (props: { envShortCode: string }) => {
    // Parent state:
    const { envShortCode } = props;

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Query state:
    const {
        data: environmentResponse,
        // TODO: Maybe show the explicit error message to the user?
        error: getEnvironmentError,
    } = useEnvironmentQuery(envShortCode, {
        refetchInterval: 5 * 1000, // 5 seconds
        enabled: envShortCode !== "",
        retry: false, // Do not retry on error
    });

    const queryClient = useQueryClient();
    const { mutate: mutateReprovisionEnvironment, reset: resetMutation } = useMutation({
        mutationFn: () => reprovisionEnvironment(envShortCode, auth0TokenOptions),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: GET_ENVIRONMENT_QUERY_KEY(envShortCode) });
        },
    });

    // Derived state:
    const environmentStatus = environmentResponse?.status;
    const provisioningStepHistory = environmentResponse?.provisioningSteps || [];

    return (
        <>
            <Header as="h3" textAlign="center">
                Setting up your Predibase environment...
            </Header>
            <div style={{ color: SEMANTIC_GREY, fontSize: "12", marginBottom: "20px" }}>
                Approximately 20 minutes total.
            </div>
            {environmentStatus === EnvironmentStatus.PROVISIONING && (
                <div>
                    You're done for now so just hang tight, grab a coffee, and when we're done, we'll send you an email
                    notification.
                </div>
            )}
            <Divider hidden />
            {!!environmentStatus && (
                <div style={{ textTransform: "capitalize" }}>
                    <b>Status: {environmentStatus}</b>
                </div>
            )}
            <Divider hidden />
            {provisioningStepHistory.map((step: string, index: number) => (
                <div key={`${step}_${index}`} style={{ display: "flex", marginBottom: "16px" }}>
                    <div style={provisioningStepCounterStyle}>{index + 1}</div>
                    <div> {step.charAt(0).toUpperCase() + step.slice(1)}</div>
                </div>
            ))}
            <Divider hidden />
            {isRetryable(environmentStatus) ? (
                <Button
                    style={{ width: "100%" }}
                    fluid
                    size="large"
                    name="method"
                    type="submit"
                    onClick={() => {
                        resetMutation();
                        mutateReprovisionEnvironment();
                    }}
                >
                    Retry
                </Button>
            ) : getEnvironmentError === null ? (
                <Loader inline active />
            ) : null}
            {environmentStatus === EnvironmentStatus.ERRORED && (
                <Message negative>
                    <Message.Header>Error</Message.Header>
                    <div>The environment creation process failed. Please retry or contact your admin</div>
                </Message>
            )}
        </>
    );
};

export default ProvisioningStepCounter;
