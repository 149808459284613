import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getUTMParams, useInviteParams } from "../hooks";

const ForwardFreeTrial = () => {
    // Router/URL state:
    const inviteTokenHook = useInviteParams();

    // URL state:
    const [searchParams] = useSearchParams();
    const connection = searchParams.get("connection") || "Username-Password-Authentication";
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        // Save the UTM parameters to local storage
        const utmParams = getUTMParams();
        const utmParamsString = Object.keys(utmParams).map((key) => `${key}=${utmParams[key as keyof typeof utmParams]}`).join("&")
        inviteTokenHook.setUtmParams(utmParamsString);

        loginWithRedirect({
            authorizationParams: {
                screen_hint: "signup",
                connection: connection,
            },
        })
    }, []);

    return null;
}

export default ForwardFreeTrial;
