import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useHotkeys } from "react-hotkeys-hook";
import { useRecoilState } from "recoil";
import { Button, Grid, Icon, Popup } from "semantic-ui-react";
import { isDeploymentPromptable } from "../deployments/misc/utils";
import metrics from "../metrics/metrics";
import { USER_STATE } from "../state/global";
import { SEMANTIC_BLACK, SEMANTIC_GREY_DISABLED } from "../utils/colors";
import Editor from "./Editor";
import PromptTemplate from "./PromptTemplate";
import { userHasEnteredTemplateValue } from "./utils/data-utils";
import { usePromptState } from "./utils/reducer";

const RunButton = (props: {
    canSubmit: boolean;
    queryIsLoading: boolean;
    submitPrompt: () => void;
    disabledMessage?: string;
}) => {
    // Parent props:
    const { canSubmit, queryIsLoading, submitPrompt, disabledMessage } = props;

    // Determine the user's platform
    const isMac = navigator.userAgent.toLowerCase().includes('mac');
    const shortcutText = isMac ? "⌘ + Enter" : "Ctrl + Enter";

    if (disabledMessage) {
        return (
            <div style={{ float: "left" }}>
                <Popup
                    position={"top center"}
                    trigger={
                        <div style={{ display: "inline-block" }}>
                            <Button
                                style={{
                                    background: SEMANTIC_GREY_DISABLED,
                                }}
                                size={"small"}
                                className={metrics.BLOCK_AUTO_CAPTURE}
                                primary
                                disabled={true}
                            >
                                <Icon name="play" size="small" />
                                Run
                            </Button>
                        </div>
                    }
                    content={disabledMessage}
                ></Popup>
            </div>
        );
    }

    return (
        <div style={{ float: "left" }}>
            <Popup
                position="top center"
                trigger={
                    <Button
                        style={{
                            background: canSubmit
                                ? "linear-gradient(270deg, #2185D0 -2.23%, #6B02BD 100%)"
                                : SEMANTIC_GREY_DISABLED,
                        }}
                        size={"small"}
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        loading={queryIsLoading}
                        onClick={submitPrompt}
                        primary
                        disabled={!canSubmit}
                    >
                        <Icon name="play" size="small" />
                        Run
                    </Button>
                }
                content={`Shortcut: Run (${shortcutText})`}
            />
        </div>
    );
};

const CancelButton = (props: { cancelGenerateQuery: () => void }) => {
    const { cancelGenerateQuery } = props;
    return (
        <div style={{ float: "left" }}>
            <Button
                style={{
                    background: SEMANTIC_BLACK,
                }}
                size={"small"}
                className={metrics.BLOCK_AUTO_CAPTURE}
                onClick={cancelGenerateQuery}
                primary
            >
                Cancel
            </Button>
        </div>
    );
};

const StopButton = (props: { stopGenerateQuery: () => void }) => {
    const { stopGenerateQuery } = props;

    return (
        <div style={{ float: "left" }}>
            <Button
                style={{
                    background: SEMANTIC_BLACK,
                }}
                size={"small"}
                className={metrics.BLOCK_AUTO_CAPTURE}
                onClick={stopGenerateQuery}
                primary
            >
                Stop
            </Button>
        </div>
    );
};

const PromptTemplateButton = (props: {
    promptTemplateVisible: boolean;
    setPromptTemplateVisible: (value: React.SetStateAction<boolean>) => void;
}) => {
    const { promptTemplateVisible, setPromptTemplateVisible } = props;

    return (
        <div style={{ float: "right" }}>
            <Button
                style={{
                    background: SEMANTIC_BLACK,
                }}
                size={"small"}
                className={metrics.BLOCK_AUTO_CAPTURE}
                onClick={() => {
                    setPromptTemplateVisible(!promptTemplateVisible);
                }}
                primary
            >
                <FontAwesomeIcon icon="up-right-and-down-left-from-center" />
                &nbsp;&nbsp; Prompt Template
            </Button>
        </div>
    );
};

const Workspace = (props: {
    promptTemplateVisible: boolean;
    setPromptTemplateVisible: (value: React.SetStateAction<boolean>) => void;
    pingDeploymentToScaleUp: () => void;
    responseHasFinishedStreaming: boolean;
    queryIsLoading: boolean;
    queryIsRefetching: boolean;
    stopGenerateQuery: () => void;
    cancelGenerateQuery: () => void;
    submitPrompt: () => void;
    apiTokenError?: string;
}) => {
    const {
        promptTemplateVisible,
        setPromptTemplateVisible,
        pingDeploymentToScaleUp,
        responseHasFinishedStreaming,
        queryIsLoading,
        queryIsRefetching,
        stopGenerateQuery,
        cancelGenerateQuery,
        submitPrompt,
        apiTokenError,
    } = props;
    // User state:
    const [userContext] = useRecoilState(USER_STATE);

    // Reducer state:
    const promptState = usePromptState();
    const { selectedDeployment, selectedAdapter, prompt, promptTemplate } = promptState;
    const promptTemplateVariables = { ...promptState.promptTemplateVariables };

    // Local state and computations:
    // Booleans:
    const promptIsNotEmpty = Boolean(
        selectedDeployment && (prompt !== "" || userHasEnteredTemplateValue(promptTemplateVariables)),
    );
    const generateQueryIsLoading = queryIsLoading || queryIsRefetching;
    const userHasActiveTenant = userContext?.tenant.status === "active";

    // Disable the run button with a popup message in certain scenarios:
    let disabledMessage = "";
    if (!userHasActiveTenant) {
        disabledMessage = "You have run out of credits. Please add a credit card in order to continue using Predibase.";
    } else if (apiTokenError) {
        disabledMessage = "No API tokens available. Please generate a new token in order to prompt.";
    }

    const canSubmit =
        promptIsNotEmpty &&
        !generateQueryIsLoading &&
        isDeploymentPromptable(selectedDeployment) &&
        disabledMessage === "" &&
        responseHasFinishedStreaming;

    // Hotkeys:
    useHotkeys(
        "mod+enter",
        () => {
            if (canSubmit) {
                submitPrompt();
            }
        },
        {
            enableOnFormTags: ["TEXTAREA"],
        },
    );

    return (
        <>
            <Grid style={{ width: "100%", height: "100%", margin: "0px" }}>
                <Grid.Column
                    style={{
                        width: promptTemplateVisible ? "50%" : "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Grid.Row style={{ flexGrow: "1", overflow: _.isEmpty(promptTemplateVariables) ? "none" : "auto" }}>
                        <Editor
                            submitPrompt={submitPrompt}
                            canSubmit={canSubmit}
                            pingDeploymentToScaleUp={pingDeploymentToScaleUp}
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <RunButton
                            canSubmit={canSubmit}
                            queryIsLoading={generateQueryIsLoading}
                            submitPrompt={submitPrompt}
                            disabledMessage={disabledMessage}
                        />
                        {generateQueryIsLoading && <CancelButton cancelGenerateQuery={cancelGenerateQuery} />}
                        {!responseHasFinishedStreaming && <StopButton stopGenerateQuery={stopGenerateQuery} />}
                        {selectedAdapter && promptTemplate && !promptTemplateVisible && (
                            <PromptTemplateButton
                                promptTemplateVisible={promptTemplateVisible}
                                setPromptTemplateVisible={setPromptTemplateVisible}
                            />
                        )}
                    </Grid.Row>
                </Grid.Column>
                {selectedAdapter && promptTemplateVisible && (
                    <Grid.Column
                        style={{
                            width: "50%",
                            paddingTop: "1rem",
                            backgroundColor: "#F7F7F7",
                            overflow: "auto",
                            borderLeft: "1px solid #d4d4d5",
                        }}
                    >
                        <PromptTemplate
                            promptTemplateVisible={promptTemplateVisible}
                            setPromptTemplateVisible={setPromptTemplateVisible}
                        />
                    </Grid.Column>
                )}
            </Grid>
        </>
    );
};

export default Workspace;
