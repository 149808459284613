import { setUser as setSentryUser, User, withSentryReactRouterV6Routing } from "@sentry/react";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Dimmer, Loader } from "semantic-ui-react";
import AdaptersViewRouter from "./adapters/AdaptersViewRouter";
import { role, tenantStatus, tier } from "./api_generated";
import ForwardFreeTrial from "./auth/auth0/ForwardFreeTrial";
import InviteView from "./auth/auth0/InviteView";
import SigninView from "./auth/auth0/SigninView";
import { welcomeUrl } from "./auth/auth0/utils";
import WelcomeView from "./auth/auth0/WelcomeView";
import { useAuth0Auth, useInviteParams, useKratosAuth } from "./auth/hooks";
import KratosAccountSettingsView from "./auth/kratos/KratosAccountSettingsView";
import KratosErrorView from "./auth/kratos/KratosErrorView";
import KratosRecoveryView from "./auth/kratos/KratosRecoveryView";
import KratosSigninView from "./auth/kratos/KratosSigninView";
import KratosSignupView from "./auth/kratos/KratosSignupView";
import VPCSetupView from "./auth/vpc/VPCSetupView";
import ErrorBoundary from "./components/ErrorBoundary";
import GlobalHeader from "./components/GlobalHeader/GlobalHeader";
import InternalBanner from "./components/InternalBanner";
import PosthogBanner from "./components/PosthogBanner";
import { EnvironmentStatus, useAuth0TokenOptions } from "./data";
import DataViewRouter from "./data/DataViewRouter";
import DeploymentsViewRouter from "./deployments/DeploymentsViewRouter";
import EnginesView from "./engines/EnginesView";
import HomePage from "./home/HomePage";
import MainMenu from "./MainMenu";
import { identify as juneIdentify, identifyGroup as juneIdentifyGroup, track as juneTrack } from "./metrics/june";
import metrics from "./metrics/metrics";
import ModelsViewRouter from "./models/ModelsViewRouter";
import PromptView from "./prompt/PromptView";
import { useEnvironmentsQuery, useUserContextQuery, useUserContextQueryV2 } from "./query";
import SettingsView from "./settings/SettingsView";
import { ENVIRONMENT_SHORTCODE_STATE, SESSION_STATE, USER_STATE } from "./state/global";
import { isLocal } from "./utils/environment";
import updateFavicon from "./utils/favicon";
import { FeatureFlagsContext, POSTHOG_FEATURE_FLAG } from "./utils/feature-flags";
import { isKratosSession, isKratosUserContext } from "./utils/kratos";

const CreateGrid = (props: { headerName: string | null; component: JSX.Element; menuComponent: JSX.Element }) => {
    const [userCtx] = useRecoilState(USER_STATE);

    return (
        <div style={{ display: "flex" }}>
            <div style={{ width: "88px" }}>{props.menuComponent}</div>
            <div style={{ width: "100%", overflowX: "auto" }}>
                {props.headerName ? (
                    <GlobalHeader userContext={userCtx} headerName={props.headerName} component={props.component} />
                ) : (
                    props.component
                )}
            </div>
        </div>
    );
};

const StyledLoader = () => {
    const style = {
        position: "fixed",
        left: "0px",
        top: "0px",
        width: "100%",
        height: "100%",
        zIndex: 9999,
    } as React.CSSProperties;
    return (
        <div style={{ ...style }}>
            <Dimmer active inverted size="massive">
                <Loader inverted>Loading</Loader>
            </Dimmer>
        </div>
    );
};

export enum EnvironmentConfigurationStatus {
    NOT_CONFIGURED = "not_configured",
    PARTLY_CONFIGURED = "partly_configured",
    FULLY_CONFIGURED = "fully_configured",
}

export const getEnvironmentConfigurationStatus = (status?: EnvironmentStatus) => {
    if (!status) {
        return undefined;
    }

    switch (status) {
        case EnvironmentStatus.CREATED:
            return EnvironmentConfigurationStatus.NOT_CONFIGURED;
        case EnvironmentStatus.READY: // NOTE: As long as the environment status has reached READY once, they can use the app.
        case EnvironmentStatus.UPDATING:
        case EnvironmentStatus.ERRORED: // ! BUG: If the environment goes from CREATED to ERRORED before READY then we should still show the setup view
            return EnvironmentConfigurationStatus.FULLY_CONFIGURED;
        default:
            return EnvironmentConfigurationStatus.PARTLY_CONFIGURED;
    }
};

// Fetches the shortcode and status information for the root (i.e. last available) environment. At the moment, there is
// only environment per tenant, so in practice the only diference between this and the singular useEnvironmentQuery is
// that you don't need to pass the environment shortcode as a parameter. As a result, this is one of the first calls the
// app makes after a user logs in.
export const useSetupRootEnvironmentInfo = (loggedInToPredibase: boolean) => {
    // Local state:
    const [envShortCode, setEnvShortCode] = useState("");
    const [envConfigStatus, setEnvConfigStatus] = useState<EnvironmentConfigurationStatus | undefined>(undefined);

    // Recoil state:
    const [, setEnvironmentShortCodeState] = useRecoilState(ENVIRONMENT_SHORTCODE_STATE);

    // Environments state:
    const { data: environments } = useEnvironmentsQuery({
        enabled: loggedInToPredibase,
    });
    // For now, we only support one environment per tenant. Grab the last one.
    const rootEnvShortCode = environments?.[environments.length - 1].shortCode;
    const rootEnvStatus = environments?.[environments.length - 1].status;
    useEffect(() => {
        if (rootEnvShortCode) {
            const envConfigStatus = getEnvironmentConfigurationStatus(rootEnvStatus);

            // Set local (hook) state:
            setEnvShortCode(rootEnvShortCode);
            setEnvConfigStatus(envConfigStatus);

            // Set recoil state:
            setEnvironmentShortCodeState(rootEnvShortCode);
        }
    }, [rootEnvShortCode]);

    return {
        envShortCode,
        envConfigStatus,
    };
};

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

// Protects pages that should only be accessed by anonymous users
const AnonymousBoundary = (props: {
    children: ReactNode;
    auth0Enabled: boolean;
    hasAuthSession: boolean;
    loggedIn: boolean;
}) => {
    const { children, auth0Enabled, loggedIn, hasAuthSession } = props;
    const navigate = useNavigate();

    // TODO: Grab any QSPs and redirect to the correct page
    // Redirect tracker:
    // ? NOTE: To protect the app from being shown (and to prevent unnecessary Auth0 popups), assume the user is not
    // allowed to access the page until proven otherwise:
    const [redirect, setRedirect] = useState(true);
    useEffect(() => {
        if (loggedIn) {
            navigate("/");
        } else if (hasAuthSession && auth0Enabled) {
            navigate(welcomeUrl);
        } else {
            setRedirect(false);
        }
    }, [loggedIn, hasAuthSession, auth0Enabled]);

    if (redirect) {
        return null;
    }

    return children;
};

// Protects pages that should only be accessed by users with a valid Auth0 session
// but no Predibase account yet
const AuthOnlyBoundary = (props: {
    children: ReactNode;
    auth0Enabled: boolean;
    hasAuthSession: boolean;
    loggedIn: boolean;
}) => {
    const { children, loggedIn, hasAuthSession } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const inviteTokenHook = useInviteParams();
    const inviteToken = inviteTokenHook.inviteToken;
    const returnTo = location.pathname;

    // Redirect tracker:
    // ? NOTE: To protect the app from being shown (and to prevent unnecessary Auth0 popups), assume the user is not
    // allowed to access the page until proven otherwise:
    const [redirect, setRedirect] = useState(true);
    useEffect(() => {
        if (!hasAuthSession) {
            navigate(`/auth/signin?return_to=${returnTo}${inviteToken ? `&inviteToken=${inviteToken}` : ""}`);
        }

        // When users are switching tenants, they will be fully logged in:
        // TODO: Should we show a specific error message here?
        else if (loggedIn && !inviteToken) {
            navigate("/");
        } else {
            setRedirect(false);
        }
    }, [hasAuthSession, loggedIn, returnTo, inviteToken]);

    if (redirect) {
        return null;
    }

    return children;
};

// Protects pages that should only be accessed by signed in users with a Predibase account
const SignedInBoundary = (props: {
    children: ReactNode;
    auth0Enabled: boolean;
    hasAuthSession: boolean;
    loggedIn: boolean;
}) => {
    // Parent props:
    const { children, auth0Enabled, hasAuthSession, loggedIn } = props;

    // Fetch root environment:
    const { envConfigStatus } = useSetupRootEnvironmentInfo(loggedIn);

    // Router state:
    const location = useLocation();
    const pathname = location.pathname;
    const navigate = useNavigate();

    // Invite token state:
    const inviteTokenHook = useInviteParams();
    const inviteToken = inviteTokenHook.inviteToken;

    // Redirect tracker:
    // ? NOTE: To protect the app from being shown (and to prevent unnecessary Auth0 popups), assume the user is not
    // allowed to access the page until proven otherwise:
    const [redirect, setRedirect] = useState(true);
    useEffect(() => {
        // Anonymous users need to sign into the app via our Auth Provider (Kratos or Auth0)
        if (!hasAuthSession) {
            metrics.capture("signin_redirect", {
                source: "private_route",
            });
            navigate(`/auth/signin?return_to=${pathname}${inviteToken ? `&inviteToken=${inviteToken}` : ""}`);
        }
        // If a user has a valid auth session but is not logged in, they need to finish setting up their account
        else if (!loggedIn && auth0Enabled) {
            navigate(`${welcomeUrl}?return_to=${pathname}${inviteToken ? `&inviteToken=${inviteToken}` : ""}`);
        }
        // Auth0 is redirecting to the Home Page instead of the Auth Welcome Page.
        // Send users to the Auth Welcome page to complete the signup process:
        else if (pathname !== welcomeUrl && inviteToken) {
            navigate(`${welcomeUrl}${inviteToken ? `?inviteToken=${inviteToken}` : ""}`);
        }
        // Reaching this point means the user is trying to provision a VPC environment that is not ready, so forward them
        // to the VPC view router:
        else if (envConfigStatus !== undefined && envConfigStatus !== EnvironmentConfigurationStatus.FULLY_CONFIGURED) {
            navigate(`/vpc/setup`);
        } else {
            setRedirect(false);
        }
    }, [hasAuthSession, loggedIn, pathname, inviteToken, envConfigStatus]);

    if (redirect) {
        return null;
    }

    return children;
};

const Predibase = () => {
    const { posthogFeatureFlags } = useContext(FeatureFlagsContext);

    // PostHog returns undefined if the feature flags are not loaded yet.
    if (!Array.isArray(posthogFeatureFlags)) {
        return <StyledLoader />;
    }

    const auth0Enabled = posthogFeatureFlags.includes(POSTHOG_FEATURE_FLAG.Auth0);
    return <PredibaseAuthorizationLayer useAuth0={auth0Enabled} />;
};

const isPredibaseAuthLayerLoading = (
    authLoading: boolean,
    hasValidAuthSession: boolean,
    userContextLoading: boolean,
) => {
    // Auth service
    // Predibase User Context
    // Unknown state, auth service hasn't responded yet
    if (authLoading) {
        return true;
    }

    // Anonymous user (No Auth0/Kratos or Pbase account)
    // Auth service has responded, since user doesn't have a session they are anonymous
    if (!hasValidAuthSession) {
        return false;
    }

    // Auth only user (No Pbase account yet)
    // Waiting to see if user has a Pbase account
    if (userContextLoading) {
        return true;
    }

    // Auth service and Predibase user context have responded
    return false;
};

const PredibaseAuthorizationLayer = (props: { useAuth0: boolean }) => {
    const { useAuth0 } = props;

    // Global Auth state:
    const [session] = useRecoilState(SESSION_STATE);
    const [, setUserContext] = useRecoilState(USER_STATE);

    const { authLoading: kratosLoading, validSession: kratosValidSession } = useKratosAuth(!useAuth0);
    const { authLoading: auth0Loading, validSession: auth0ValidSession } = useAuth0Auth(useAuth0);

    const authLoading = useAuth0 ? auth0Loading : kratosLoading;
    const hasValidAuthSession = useAuth0 ? auth0ValidSession : kratosValidSession;

    // TODO: Show an error message somewhere if this fails:
    const { data: fetchedUserContextV1, isLoading: userContextLoadingV1 } = useUserContextQuery({
        // TODO: Clean up session handling:
        enabled: hasValidAuthSession && !useAuth0,
        // TODO: We may eventually want to refetch, see disc.:
        // https://github.com/predibase/predibase/pull/6906/files/3c3762b514e23a03885fd7d32fb86bf60988684f..560c84e02a5aae048edb8af3b2fe0d96268a6b5a#r1525653492
        // refetchOnWindowFocus: false,
        retry: false,
    });
    const { data: fetchedUserContextV2, isLoading: userContextLoadingV2 } = useUserContextQueryV2({
        // TODO: Clean up session handling:
        enabled: hasValidAuthSession && useAuth0,
        // TODO: We may eventually want to refetch, see disc.:
        // https://github.com/predibase/predibase/pull/6906/files/3c3762b514e23a03885fd7d32fb86bf60988684f..560c84e02a5aae048edb8af3b2fe0d96268a6b5a#r1525653492
        // refetchOnWindowFocus: false,
        retry: false,
    });

    const fetchedUserContext = useAuth0 ? fetchedUserContextV2 : fetchedUserContextV1;
    const userContextLoading = useAuth0 ? userContextLoadingV2 : userContextLoadingV1;

    useEffect(() => {
        if (!session || !fetchedUserContext) {
            return;
        }

        const isKratosSess = isKratosSession(session);
        const email = isKratosSess ? session?.identity?.traits?.email : (session as User)?.email;
        const isKratosCtx = isKratosUserContext(fetchedUserContext);
        const tenantTier = isKratosCtx ? fetchedUserContext?.tenant.subscription.tier : fetchedUserContext?.tenant.tier;
        const shortCode = isKratosCtx ? fetchedUserContext.tenant.shortCode : fetchedUserContext.tenant.shortcode;
        const user = {
            ...fetchedUserContext,
            isSystemUser: isKratosCtx
                ? fetchedUserContext?.isSystemUser
                : fetchedUserContext.role.toLowerCase() === role.ADMIN ||
                  fetchedUserContext.role.toLowerCase() === role.SUPPORT,
            isExpired: fetchedUserContext.tenant.status === tenantStatus.SUSPENDED,
            isComputeLimited: tenantTier === tier.FREE,
            isFeatureLimited: false, // This is here in case we want to limit certain features in the future
        };

        setSentryUser({
            id: String(user.uuid),
            username: user.username,
            tenantID: String(user.tenant.uuid),
            tenantName: user.tenant?.name,
            isSystemUser: user.isSystemUser,
        });

        // June metrics
        juneIdentify(user, email).then(() => {
            juneIdentifyGroup(user).then(() => {
                if (user.isExpired) {
                    juneTrack(user, "expired_trial_login", {
                        username: user.username,
                        userID: user.uuid,
                        subscription: tenantTier,
                    });
                }
            });
        });

        // PostHog metrics
        metrics.identify(user.uuid, email, user.username, tenantTier, shortCode);
        metrics.group("company", user.tenant.uuid, {
            name: user.tenant.name,
            shortCode: shortCode,
            subscription: tenantTier,
            status: user.tenant.status,
        });
        if (user.isExpired) {
            metrics.capture("expired_trial_login", {
                username: user.username,
                userID: user.uuid,
                subscription: tenantTier,
            });
        }

        setUserContext(user);
    }, [session, fetchedUserContext]);

    const isLoggedIn = Boolean(hasValidAuthSession && fetchedUserContext);
    const isAuthLayerloading = isPredibaseAuthLayerLoading(authLoading, hasValidAuthSession, userContextLoading);

    if (isAuthLayerloading) {
        return <StyledLoader />;
    }

    return <PredibasePage hasValidAuthSession={hasValidAuthSession} loggedInToPredibase={isLoggedIn} />;
};

const PredibasePage = (props: { hasValidAuthSession: boolean; loggedInToPredibase: boolean }) => {
    const { loggedInToPredibase, hasValidAuthSession } = props;

    // Global Auth state:
    const [userContext] = useRecoilState(USER_STATE);
    const isViewerAdmin = userContext?.isSystemUser;

    // Meta state:
    const location = useLocation();

    // Feature flags:
    const { auth0Enabled } = useAuth0TokenOptions();

    // Update the favicon every time the route changes
    // so that the user doesn't see a stale status in the
    // browser window.
    useEffect(() => {
        updateFavicon();
    }, [location]);

    return (
        <>
            <PosthogBanner />
            <InternalBanner />
            <SentryRoutes>
                {/* Unguarded routes: */}
                <Route
                    path="/auth/signin"
                    element={
                        <AnonymousBoundary
                            auth0Enabled={auth0Enabled}
                            hasAuthSession={hasValidAuthSession}
                            loggedIn={loggedInToPredibase}
                        >
                            {auth0Enabled ? <SigninView /> : <KratosSigninView />}
                        </AnonymousBoundary>
                    }
                />
                <Route
                    path="/recovery"
                    element={
                        <AnonymousBoundary
                            auth0Enabled={auth0Enabled}
                            hasAuthSession={hasValidAuthSession}
                            loggedIn={loggedInToPredibase}
                        >
                            <KratosRecoveryView />
                        </AnonymousBoundary>
                    }
                />
                <Route
                    path="/account/settings"
                    element={
                        <AnonymousBoundary
                            auth0Enabled={auth0Enabled}
                            hasAuthSession={hasValidAuthSession}
                            loggedIn={loggedInToPredibase}
                        >
                            <KratosAccountSettingsView />
                        </AnonymousBoundary>
                    }
                />
                <Route
                    path="/auth/signup"
                    element={
                        <AnonymousBoundary
                            auth0Enabled={auth0Enabled}
                            hasAuthSession={hasValidAuthSession}
                            loggedIn={loggedInToPredibase}
                        >
                            <KratosSignupView />
                        </AnonymousBoundary>
                    }
                />
                <Route
                    path="/auth/error"
                    element={
                        <AnonymousBoundary
                            auth0Enabled={auth0Enabled}
                            hasAuthSession={hasValidAuthSession}
                            loggedIn={loggedInToPredibase}
                        >
                            <KratosErrorView />
                        </AnonymousBoundary>
                    }
                />
                <Route
                    path="/auth/forward_free_trial"
                    element={
                        <AnonymousBoundary
                            auth0Enabled={auth0Enabled}
                            hasAuthSession={hasValidAuthSession}
                            loggedIn={loggedInToPredibase}
                        >
                            <ForwardFreeTrial />
                        </AnonymousBoundary>
                    }
                />
                <Route
                    path={welcomeUrl}
                    element={
                        <AuthOnlyBoundary
                            auth0Enabled={auth0Enabled}
                            hasAuthSession={hasValidAuthSession}
                            loggedIn={loggedInToPredibase}
                        >
                            <WelcomeView />
                        </AuthOnlyBoundary>
                    }
                />
                <Route
                    path="/invite"
                    element={
                        <AnonymousBoundary
                            auth0Enabled={auth0Enabled}
                            hasAuthSession={hasValidAuthSession}
                            loggedIn={loggedInToPredibase}
                        >
                            <InviteView />
                        </AnonymousBoundary>
                    }
                />

                {/* Top level redirects: */}
                {/* TODO: remove at some point? */}
                <Route path="/queries" element={<Navigate to="/prompt" replace />} />

                {/* Guarded routes: */}

                {/* TODO: This check should really be part of the auth boundary, right? */}
                {(isViewerAdmin || isLocal()) && (
                    <Route
                        path="/engines"
                        element={
                            <SignedInBoundary
                                auth0Enabled={auth0Enabled}
                                hasAuthSession={hasValidAuthSession}
                                loggedIn={loggedInToPredibase}
                            >
                                <CreateGrid
                                    headerName={"Engines"}
                                    component={
                                        <ErrorBoundary>
                                            <EnginesView />
                                        </ErrorBoundary>
                                    }
                                    menuComponent={<MainMenu activeItem="engines" />}
                                />
                            </SignedInBoundary>
                        }
                    />
                )}

                <Route
                    path="/vpc/setup"
                    element={
                        <SignedInBoundary
                            auth0Enabled={auth0Enabled}
                            hasAuthSession={hasValidAuthSession}
                            loggedIn={loggedInToPredibase}
                        >
                            <VPCSetupView />
                        </SignedInBoundary>
                    }
                />

                <Route
                    path="/data/*"
                    element={
                        <SignedInBoundary
                            auth0Enabled={auth0Enabled}
                            hasAuthSession={hasValidAuthSession}
                            loggedIn={loggedInToPredibase}
                        >
                            <CreateGrid
                                headerName={"Data"}
                                component={
                                    <ErrorBoundary>
                                        <DataViewRouter />
                                    </ErrorBoundary>
                                }
                                menuComponent={<MainMenu activeItem="data" />}
                            />
                        </SignedInBoundary>
                    }
                />
                <Route
                    path="/models/*"
                    element={
                        <SignedInBoundary
                            auth0Enabled={auth0Enabled}
                            hasAuthSession={hasValidAuthSession}
                            loggedIn={loggedInToPredibase}
                        >
                            <CreateGrid
                                headerName={location.pathname === "/models" ? "Model Repositories" : null}
                                component={
                                    <ErrorBoundary>
                                        <ModelsViewRouter />
                                    </ErrorBoundary>
                                }
                                menuComponent={<MainMenu activeItem="models" />}
                            />
                        </SignedInBoundary>
                    }
                />
                <Route
                    path="/adapters/*"
                    element={
                        <SignedInBoundary
                            auth0Enabled={auth0Enabled}
                            hasAuthSession={hasValidAuthSession}
                            loggedIn={loggedInToPredibase}
                        >
                            <CreateGrid
                                headerName={location.pathname === "/adapters" ? "Adapters" : null}
                                component={
                                    <ErrorBoundary>
                                        <AdaptersViewRouter />
                                    </ErrorBoundary>
                                }
                                menuComponent={<MainMenu activeItem="adapters" />}
                            />
                        </SignedInBoundary>
                    }
                />
                <Route
                    path="/prompt"
                    element={
                        <SignedInBoundary
                            auth0Enabled={auth0Enabled}
                            hasAuthSession={hasValidAuthSession}
                            loggedIn={loggedInToPredibase}
                        >
                            <CreateGrid
                                headerName={"Prompt"}
                                component={
                                    <ErrorBoundary>
                                        <PromptView />
                                    </ErrorBoundary>
                                }
                                menuComponent={<MainMenu activeItem="prompt" />}
                            />
                        </SignedInBoundary>
                    }
                />
                <Route
                    path="/deployments/*"
                    element={
                        <SignedInBoundary
                            auth0Enabled={auth0Enabled}
                            hasAuthSession={hasValidAuthSession}
                            loggedIn={loggedInToPredibase}
                        >
                            <CreateGrid
                                headerName={location.pathname === "/deployments" ? "Deployments" : null}
                                component={
                                    <ErrorBoundary>
                                        <DeploymentsViewRouter />
                                    </ErrorBoundary>
                                }
                                menuComponent={<MainMenu activeItem="deployments" />}
                            />
                        </SignedInBoundary>
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <SignedInBoundary
                            auth0Enabled={auth0Enabled}
                            hasAuthSession={hasValidAuthSession}
                            loggedIn={loggedInToPredibase}
                        >
                            <CreateGrid
                                headerName={"Settings"}
                                component={
                                    <ErrorBoundary>
                                        <SettingsView />
                                    </ErrorBoundary>
                                }
                                menuComponent={<MainMenu activeItem="settings" />}
                            />
                        </SignedInBoundary>
                    }
                />
                <Route
                    path="/"
                    element={
                        <SignedInBoundary
                            auth0Enabled={auth0Enabled}
                            hasAuthSession={hasValidAuthSession}
                            loggedIn={loggedInToPredibase}
                        >
                            <CreateGrid
                                headerName={"Home"}
                                component={
                                    <ErrorBoundary>
                                        <HomePage />
                                    </ErrorBoundary>
                                }
                                menuComponent={<MainMenu activeItem="home" />}
                            />
                        </SignedInBoundary>
                    }
                />
            </SentryRoutes>
        </>
    );
};

export default Predibase;
