import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { adapterVersion, finetuningJob, finetuningJobTimeline, listReposResponse, repo } from "../api_generated";
import { useAuth0TokenOptions } from "../data";
import {
    generateDeploymentSelectorOptions,
    generateDeploymentToAdapterRepoMap,
    sortDeployments,
} from "../prompt/utils/dropdown-utils";
import { matchQueryString } from "../prompt/utils/model-matching";
import { ADAPTERS_CONSTANT, useDeploymentsQuery } from "../query";
import {
    getAdapterRepo,
    getAdapterRepos,
    getAdapterVersion,
    getFinetuningJob,
    getFinetuningJobTimeline,
    listAdapterReposParams
} from "./data";

// Constants:
export const REPOS_CONSTANT = "repos";
export const JOBS_CONSTANT = "jobs";
export const FINETUNING_CONSTANT = "finetuning";

// Queries:
export const GET_ADAPTER_REPOS_QUERY_KEY = (paginationParams?: listAdapterReposParams) =>
    paginationParams ? [ADAPTERS_CONSTANT, REPOS_CONSTANT, paginationParams] : [ADAPTERS_CONSTANT, REPOS_CONSTANT];
export const useAdapterReposQuery = (
    params?: listAdapterReposParams,
    options?: Partial<UseQueryOptions<listReposResponse>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<listReposResponse>({
        queryKey: GET_ADAPTER_REPOS_QUERY_KEY(params), // TODO: Does every value need to be a string?
        queryFn: () => getAdapterRepos(params, auth0TokenOptions),
        ...options,
    });
};

export const GET_ADAPTER_REPO_QUERY_KEY = (uuid: string) => [ADAPTERS_CONSTANT, REPOS_CONSTANT, uuid];
export const useAdapterRepoQuery = (uuid: string, options?: Partial<UseQueryOptions<repo>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<repo>({
        queryKey: GET_ADAPTER_REPO_QUERY_KEY(uuid),
        queryFn: () => getAdapterRepo(uuid, auth0TokenOptions),
        ...options,
    });
};

export const GET_ADAPTER_VERSION_QUERY_KEY = (repoUUID: string, versionTag: number) => [
    ADAPTERS_CONSTANT,
    REPOS_CONSTANT,
    repoUUID,
    "versions",
    versionTag,
];
export const useAdapterVersionQuery = (
    repoUUID: string,
    versionTag: number,
    options?: Partial<UseQueryOptions<adapterVersion>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<adapterVersion>({
        queryKey: GET_ADAPTER_VERSION_QUERY_KEY(repoUUID, versionTag),
        queryFn: () => getAdapterVersion(repoUUID, versionTag, auth0TokenOptions),
        ...options,
    });
};

export const GET_FINETUNING_JOB_TIMELINE_QUERY_KEY = (uuid: string) => [
    JOBS_CONSTANT,
    FINETUNING_CONSTANT,
    uuid,
    "timeline",
];
export const useFinetuningJobTimelineQuery = (
    fineTuningJobUUID: string,
    options?: Partial<UseQueryOptions<finetuningJobTimeline>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<finetuningJobTimeline>({
        queryKey: GET_FINETUNING_JOB_TIMELINE_QUERY_KEY(fineTuningJobUUID),
        queryFn: () => getFinetuningJobTimeline(fineTuningJobUUID, auth0TokenOptions),
        ...options,
    });
};

export const GET_FINETUNING_JOB_QUERY_KEY = (uuid: string) => [JOBS_CONSTANT, FINETUNING_CONSTANT, uuid];
export const useFinetuningJobQuery = (uuid: string, options?: Partial<UseQueryOptions<finetuningJob>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<finetuningJob>({
        queryKey: GET_FINETUNING_JOB_QUERY_KEY(uuid),
        queryFn: () => getFinetuningJob(uuid, auth0TokenOptions),
        ...options,
    });
};

// Custom hooks:
export const useDeploymentCompatibleWithAdapterVersion = (adapterVersion: adapterVersion | undefined) => {
    // Get all current deployments:
    const { data: deployments } = useDeploymentsQuery({
        refetchOnWindowFocus: false,
        refetchInterval: false,
    });
    // Get a sorted version of the deployments:
    const sortedDeployments = sortDeployments(deployments);

    if (adapterVersion === undefined) {
        return null;
    }

    const [, deploymentUUIDLookup] = generateDeploymentSelectorOptions(sortedDeployments);
    const deploymentUUIDToAdapterReposMap = generateDeploymentToAdapterRepoMap(sortedDeployments, [
        {
            archived: adapterVersion.archived,
            baseModel: adapterVersion.baseModel,
            repo: adapterVersion.repo,
            versionTag: adapterVersion.tag,
        },
    ]);

    // Otherwise, try to match the query string:
    const match = matchQueryString(
        {
            versionTag: adapterVersion.tag,
            adapterRepoName: adapterVersion.repo,
            modelIdentifier: adapterVersion.baseModel,
        },
        deploymentUUIDToAdapterReposMap,
        deploymentUUIDLookup,
    );
    const [deploymentMatch] = match;

    return deploymentMatch;
};
