import { CSSProperties, Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { ErrorBoundaryMessage } from "../../components/ErrorBoundary";
import { AvailableCloudTypes } from "../../data";
import { EnvironmentConfigurationStatus, getEnvironmentConfigurationStatus } from "../../Predibase";
import { useEnvironmentQuery } from "../../query";
import { ENVIRONMENT_SHORTCODE_STATE } from "../../state/global";
import { PREDIBASE_LIGHT_GRAY } from "../../utils/colors";
import { useGetSignOut } from "../../utils/signout";
import AWSSetupView from "./setup/aws/AWSSetupView";
import AzureSetupView from "./setup/azure/AzureSetupView";
import CloudAndRegionSelection from "./setup/CloudAndRegionSelection";
import ProvisioningStepCounter from "./setup/ProvisioningStepCounter";

import { awsVPCRegions, azureVPCRegions } from "../../api_generated";
import "./VPCSetupView.css";

const FormLogoWrapper = (props: {
    showSelectCloudBackoutButton: boolean;
    setShowCloudSpecificSetup: Dispatch<SetStateAction<boolean>>;
    widthStyle?: CSSProperties;
    envShortCode: string;
    children: ReactNode;
}) => {
    const { showSelectCloudBackoutButton, setShowCloudSpecificSetup, widthStyle, children } = props;

    return (
        <div
            style={{
                minHeight: "100vh",
                background: PREDIBASE_LIGHT_GRAY,
                display: "flex",
                justifyContent: "center",
                fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
                padding: "20px",
                position: "relative",
            }}
        >
            {showSelectCloudBackoutButton && (
                <BackToCloudSelectionButton setShowCloudSpecificSetup={setShowCloudSpecificSetup} />
            )}
            <SignoutButton />
            <div style={{ textAlign: "center", alignSelf: "center", ...widthStyle }}>
                <img src={"/logos/predibase/predibase.svg"} width={70} height={70} alt="" />
                {children}
            </div>
        </div>
    );
};

const SignoutButton = () => {
    const signout = useGetSignOut();
    return (
        <button className="button-reset nav-button top-right" onClick={signout}>
            Sign Out
        </button>
    );
};

const BackToCloudSelectionButton = (props: { setShowCloudSpecificSetup: Dispatch<SetStateAction<boolean>> }) => {
    const { setShowCloudSpecificSetup } = props;

    return (
        <button
            className="button-reset nav-button top-left"
            onClick={() => {
                setShowCloudSpecificSetup(false);
            }}
        >
            &lt; Select your cloud provider
        </button>
    );
};

const VPCSetupView = () => {
    // Recoil state:
    const [envShortCode] = useRecoilState(ENVIRONMENT_SHORTCODE_STATE);

    // Local state:
    const [showCloudSpecificSetup, setShowCloudSpecificSetup] = useState(false); // Second screen of flow

    // URL state (persist form state in URL):
    const navigate = useNavigate();
    const [urlParams, setURLParams] = useSearchParams();
    const [selectedCloud, setSelectedCloud] = useState<AvailableCloudTypes | undefined>(
        (urlParams.get("cloud") as AvailableCloudTypes | null) ?? undefined,
    );
    const [selectedRegion, setSelectedRegion] = useState<string | undefined>(urlParams.get("region") ?? undefined);
    useEffect(() => {
        if (selectedCloud) {
            urlParams.delete("region");
            setSelectedRegion(undefined);
            setURLParams({ cloud: selectedCloud });
        }
    }, [selectedCloud]);
    useEffect(() => {
        if (selectedRegion) {
            setURLParams({ cloud: selectedCloud!, region: selectedRegion });
        }
    }, [selectedRegion]);

    // Query state:
    // TODO: Show the error somewhere...
    const { data: currentEnvironmentConfigFromDB, error: getEnvironmentError } = useEnvironmentQuery(
        envShortCode ?? "",
        {
            enabled: envShortCode !== "",
        },
    );
    const envStatusFromDB = currentEnvironmentConfigFromDB?.status || undefined; // Cast empty string to undefined
    const regionFromDB = currentEnvironmentConfigFromDB?.region || undefined; // Cast empty string to undefined
    const cloudFromDB = currentEnvironmentConfigFromDB?.cloud || undefined; // Cast empty string to undefined

    // Derived state:
    const envConfigStatus = getEnvironmentConfigurationStatus(envStatusFromDB);
    const cloudAndRegionAlreadyConfigured = regionFromDB !== undefined && cloudFromDB !== undefined;
    const showCloudAndRegionSelection = // First screen of flow
        envConfigStatus === EnvironmentConfigurationStatus.NOT_CONFIGURED &&
        !cloudAndRegionAlreadyConfigured &&
        !showCloudSpecificSetup;
    const showProvisioningSteps = envConfigStatus === EnvironmentConfigurationStatus.PARTLY_CONFIGURED; // Last screen of flow

    useEffect(() => {
        if (envConfigStatus === EnvironmentConfigurationStatus.FULLY_CONFIGURED) {
            navigate("/");
        }
    }, [envConfigStatus]);

    if (!envShortCode) {
        return null;
    }

    return (
        <>
            <FormLogoWrapper
                showSelectCloudBackoutButton={showCloudSpecificSetup}
                setShowCloudSpecificSetup={setShowCloudSpecificSetup}
                widthStyle={showCloudSpecificSetup ? { width: "50vw", maxWidth: `${576 / 14}rem` } : undefined}
                envShortCode={envShortCode}
            >
                {showCloudAndRegionSelection ? (
                    <CloudAndRegionSelection
                        selectedCloud={selectedCloud}
                        setSelectedCloud={setSelectedCloud}
                        selectedRegion={selectedRegion}
                        setSelectedRegion={setSelectedRegion}
                        setShowCloudSpecificSetup={setShowCloudSpecificSetup}
                    />
                ) : showCloudSpecificSetup ? (
                    selectedCloud === "aws" ? (
                        <AWSSetupView envShortCode={envShortCode} envRegion={selectedRegion as awsVPCRegions} />
                    ) : selectedCloud === "azure" ? (
                        <AzureSetupView envShortCode={envShortCode} envRegion={selectedRegion as azureVPCRegions} />
                    ) : (
                        <ErrorBoundaryMessage />
                    )
                ) : showProvisioningSteps ? (
                    <ProvisioningStepCounter envShortCode={envShortCode} />
                ) : null}
            </FormLogoWrapper>
        </>
    );
};

export default VPCSetupView;
