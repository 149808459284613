import { UseMutateFunction, useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox, Divider, Dropdown, Header, Table } from "semantic-ui-react";
import urlJoin from "url-join";
import {
    adapterVersionListItem,
    finetuningJobStatus,
    repo,
    role,
    updateAdapterVersionRequest,
} from "../../../api_generated";
import ConnectionImagePopup from "../../../components/ConnectionImagePopup";
import LiveTimeAgo from "../../../components/LiveTimeAgo";
import { Auth0TokenOptions, useAuth0TokenOptions } from "../../../data";
import { formatAdapterType } from "../../../deployments/misc/utils";
import PromptInstructionsModal from "../../../deployments/views/modals/PromptInstructionsModal";
import metrics from "../../../metrics/metrics";
import { useUserRole } from "../../../settings/query";
import { getClientEndpoint } from "../../../utils/api";
import { SEMANTIC_GREY } from "../../../utils/colors";
import { deletedUser } from "../../../utils/constants";
import dayjsExtended, { durationAsString } from "../../../utils/dayjs";
import { cssTruncate } from "../../../utils/overflow";
import { archiveOrUnarchiveVersion, updateAdapterVersionDescription, versionArchivalParams } from "../../data";
import AdapterStatusChip from "../../misc/AdapterStatusChip";
import EditableDescription from "../../misc/EditableDescription";
import JobStatusChip from "../../misc/JobStatusChip";
import { isTerminalJobStatus } from "../../misc/utils";
import {
    GET_ADAPTER_REPO_QUERY_KEY,
    GET_ADAPTER_REPOS_QUERY_KEY,
    useAdapterVersionQuery,
    useDeploymentCompatibleWithAdapterVersion,
} from "../../query";
import { adapterTypes } from "../create/utils";
import CancelFinetuningJobModal from "../modals/CancelFinetuningJobModal";
import DeleteAdapterVersionModal from "../modals/DeleteAdapterVersionModal";
import RetrainAdapterModal from "../modals/RetrainAdapterModal";

const getAdapterTrainingDuration = (adapterVersion: adapterVersionListItem) => {
    const created = dayjsExtended(new Date(adapterVersion.createdAt));
    if (
        adapterVersion.status === finetuningJobStatus.COMPLETED ||
        adapterVersion.status === finetuningJobStatus.STOPPED
    ) {
        const completed = dayjsExtended(new Date(adapterVersion.endedAt!));
        const duration = dayjsExtended.duration(completed.diff(created));
        return duration;
    }
    return null;
};

const AdapterVersionListItemTableRow = (props: {
    repo: repo;
    adapterVersionListItem: adapterVersionListItem | undefined;
    setRetrainVersionNumber: React.Dispatch<React.SetStateAction<number | undefined>>;
    setCancelVersionNumber: React.Dispatch<React.SetStateAction<number | undefined>>;
    setDeleteVersionNumber: React.Dispatch<React.SetStateAction<number | undefined>>;
    setPromptVersion: React.Dispatch<React.SetStateAction<adapterVersionListItem | undefined>>;
    archivalMutationFn: UseMutateFunction<
        AxiosResponse<updateAdapterVersionRequest, any>,
        Error,
        versionArchivalParams,
        unknown
    >;
}) => {
    // Parent state:
    let {
        repo,
        adapterVersionListItem,
        setRetrainVersionNumber,
        setCancelVersionNumber,
        setDeleteVersionNumber,
        setPromptVersion,
        archivalMutationFn: mutationFn,
    } = props;
    const navigate = useNavigate();

    // Query state:
    const userRole = useUserRole();

    // Return empty row if adapterVersion is undefined:
    if (adapterVersionListItem === undefined) {
        return <Table.Row />;
    }

    // Derived state:
    const adapterURLPath = `/adapters/repo/${repo.uuid}/version/${adapterVersionListItem.tag}`;
    const adapterPathIsAvailable = Boolean(adapterVersionListItem.adapterPath);
    const isImportedAdapter = adapterVersionListItem.datasetId === undefined;
    const isTurboAdapter = (adapterVersionListItem.adapterType as adapterTypes) === "turbo";
    const isLoRAAdapter = (adapterVersionListItem.adapterType as adapterTypes) === "lora";
    const canBeContinued = adapterPathIsAvailable && (isImportedAdapter || isLoRAAdapter);
    const duration = getAdapterTrainingDuration(adapterVersionListItem);
    const durationString = durationAsString(duration);

    return (
        <Table.Row key={adapterURLPath}>
            <Table.Cell>
                <Link to={adapterURLPath}>{adapterVersionListItem.tag}</Link>
            </Table.Cell>
            <Table.Cell>
                <AdapterStatusChip adapterPath={adapterVersionListItem.adapterPath} />
            </Table.Cell>
            <Table.Cell disabled={adapterVersionListItem.archived}>
                {adapterVersionListItem.archived ? (
                    <>{adapterVersionListItem.description || "—"}</>
                ) : (
                    <EditableDescription
                        adapterURLPath={adapterURLPath}
                        description={adapterVersionListItem.description}
                        inputMinWidth={`${500 / 14}rem`}
                        // Already handling adapterVersion being undefined above
                        updateDescriptionFunction={(text: string, auth0TokenOptions?: Auth0TokenOptions) =>
                            updateAdapterVersionDescription(
                                repo.uuid,
                                adapterVersionListItem!.tag,
                                text,
                                auth0TokenOptions,
                            )
                        }
                        queryKeyToInvalidate={GET_ADAPTER_REPOS_QUERY_KEY()}
                    />
                )}
            </Table.Cell>
            <Table.Cell disabled={adapterVersionListItem.archived}>
                {adapterVersionListItem.baseModel ?? "—"}
            </Table.Cell>
            <Table.Cell disabled={adapterVersionListItem.archived}>
                {adapterVersionListItem.datasetId ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <ConnectionImagePopup
                            connectionType={adapterVersionListItem.datasetType}
                            style={{ marginRight: 0, width: "1.5em", height: "1.5em" }}
                        />
                        {adapterVersionListItem.archived ? (
                            <>{adapterVersionListItem.datasetName}</>
                        ) : (
                            <Link
                                className={metrics.BLOCK_AUTO_CAPTURE + " black-link"}
                                style={{ ...cssTruncate, verticalAlign: "middle", maxWidth: "300px" }}
                                to={`/data/datasets/${adapterVersionListItem.datasetId}`}
                            >
                                {adapterVersionListItem.datasetName}
                            </Link>
                        )}
                    </div>
                ) : (
                    "—"
                )}
            </Table.Cell>
            <Table.Cell disabled={adapterVersionListItem.archived}>
                {formatAdapterType(adapterVersionListItem.adapterType)}
                <br />
                {adapterVersionListItem.baseVersionRepoName &&
                    adapterVersionListItem.baseVersionTag &&
                    adapterVersionListItem.baseVersionRepoUUID && (
                        <span style={{ color: SEMANTIC_GREY, fontSize: "0.9em" }}>
                            Trained on{" "}
                            <a
                                href={urlJoin(
                                    getClientEndpoint(),
                                    `/adapters/repo/${adapterVersionListItem.baseVersionRepoUUID}/version/${adapterVersionListItem?.baseVersionTag}`,
                                )}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {adapterVersionListItem.baseVersionRepoName}/{adapterVersionListItem.baseVersionTag}
                            </a>
                        </span>
                    )}
            </Table.Cell>

            {/* TODO: Need Loss scores: */}
            {/* <Table.Cell>--</Table.Cell> */}

            <Table.Cell disabled={adapterVersionListItem.archived}>{durationString ?? "—"}</Table.Cell>
            <Table.Cell disabled={adapterVersionListItem.archived}>
                <LiveTimeAgo fromDate={new Date(adapterVersionListItem.createdAt)} />
                <br />
                <span style={{ color: SEMANTIC_GREY, fontSize: "0.9em" }}>
                    {adapterVersionListItem.createdBy || deletedUser}
                </span>
            </Table.Cell>
            <Table.Cell textAlign="center" disabled={adapterVersionListItem.archived}>
                <JobStatusChip status={adapterVersionListItem.status} />
            </Table.Cell>
            <Table.Cell collapsing>
                <Dropdown
                    closeOnBlur
                    direction="left"
                    icon="ellipsis vertical"
                    className="icon mini basic"
                    style={{ color: SEMANTIC_GREY }}
                >
                    <Dropdown.Menu style={{ borderStyle: "solid" }}>
                        {adapterPathIsAvailable && (
                            <>
                                <Dropdown.Item
                                    onClick={() =>
                                        navigate(
                                            `/prompt?version=${adapterVersionListItem?.tag}&name=${encodeURIComponent(
                                                repo.name,
                                            )}&model=${encodeURIComponent(adapterVersionListItem?.baseModel ?? "")}`,
                                        )
                                    }
                                >
                                    Prompt via UI
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => {
                                        setPromptVersion(adapterVersionListItem);
                                    }}
                                >
                                    Prompt via REST API/SDK/CLI
                                </Dropdown.Item>
                            </>
                        )}
                        {userRole !== role.READONLY && (
                            <>
                                {!isImportedAdapter && !isTurboAdapter && (
                                    <Dropdown.Item
                                        onClick={() => {
                                            window.open(
                                                `/adapters/create/${repo.uuid}?adapterVersionID=${adapterVersionListItem?.tag}`,
                                                "_blank",
                                            );
                                        }}
                                    >
                                        New adapter from #{adapterVersionListItem.tag}
                                    </Dropdown.Item>
                                )}
                                {!isImportedAdapter && (
                                    <Dropdown.Item
                                        onClick={() => {
                                            setRetrainVersionNumber(adapterVersionListItem?.tag);
                                        }}
                                    >
                                        Retrain Adapter #{`${adapterVersionListItem.tag}`}
                                    </Dropdown.Item>
                                )}
                                {canBeContinued && (
                                    <Dropdown.Item
                                        onClick={() => {
                                            window.open(
                                                `/adapters/create/${repo.uuid}/continue?adapterVersionID=${adapterVersionListItem?.tag}`,
                                                "_blank",
                                            );
                                        }}
                                    >
                                        (Beta) Continue Training Adapter
                                    </Dropdown.Item>
                                )}
                                {!isImportedAdapter &&
                                    !isTerminalJobStatus(adapterVersionListItem.status) &&
                                    adapterVersionListItem.status !== finetuningJobStatus.STOPPING && (
                                        <Dropdown.Item
                                            onClick={() => {
                                                setCancelVersionNumber(adapterVersionListItem?.tag);
                                            }}
                                        >
                                            Stop
                                        </Dropdown.Item>
                                    )}
                                {(adapterVersionListItem.status === undefined ||
                                    isTerminalJobStatus(adapterVersionListItem.status)) &&
                                    (adapterVersionListItem.archived ? (
                                        <>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    // TODO: reset?
                                                    mutationFn({
                                                        repoUUID: repo.uuid,
                                                        versionTag: adapterVersionListItem.tag,
                                                        archive: false,
                                                    });
                                                }}
                                            >
                                                Unarchive
                                            </Dropdown.Item>
                                        </>
                                    ) : (
                                        <>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    // TODO: reset?
                                                    mutationFn({
                                                        repoUUID: repo.uuid,
                                                        versionTag: adapterVersionListItem.tag,
                                                        archive: true,
                                                    });
                                                }}
                                            >
                                                Archive
                                            </Dropdown.Item>
                                        </>
                                    ))}
                                {(adapterVersionListItem.status === undefined ||
                                    isTerminalJobStatus(adapterVersionListItem.status)) && (
                                    <Dropdown.Item
                                        onClick={() => {
                                            setDeleteVersionNumber(adapterVersionListItem?.tag);
                                        }}
                                    >
                                        Delete
                                    </Dropdown.Item>
                                )}
                            </>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </Table.Cell>
        </Table.Row>
    );
};

const AdapterVersionsTable = (props: { repo: repo }) => {
    // Parent state:
    const { repo } = props;

    // Local state:
    const [showArchived, setShowArchived] = useState(false);

    // Modal state:
    const [adapterRetrainVersionNumber, setAdapterRetrainVersionNumber] = useState<number | undefined>(undefined);
    const [adapterCancelVersionNumber, setAdapterCancelVersionNumber] = useState<number | undefined>(undefined);
    const [adapterDeleteVersionNumber, setAdapterDeleteVersionNumber] = useState<number | undefined>(undefined);
    const [adapterPromptVersion, setAdapterPromptVersion] = useState<adapterVersionListItem | undefined>(undefined);

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Query state:
    const queryClient = useQueryClient();

    const { data: selectedAdapter, isLoading: adapterIsLoading } = useAdapterVersionQuery(
        repo.uuid,
        adapterPromptVersion?.tag ?? -1,
        {
            enabled: adapterPromptVersion?.tag !== undefined,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        },
    );
    const compatibleDeployment = useDeploymentCompatibleWithAdapterVersion(selectedAdapter) ?? undefined;

    // Sort versions descending by tag
    let versions = repo.versions;
    if (Array.isArray(versions)) {
        versions = [...versions];
        versions.sort((a, b) => Number(b.tag) - Number(a.tag));
    }

    const { mutate: archivalMutationFn } = useMutation({
        mutationFn: (params: versionArchivalParams) => archiveOrUnarchiveVersion(params, auth0TokenOptions),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: GET_ADAPTER_REPO_QUERY_KEY(repo.uuid) });
        },
    });

    return (
        <>
            <Checkbox
                toggle
                style={{
                    verticalAlign: "middle",
                    display: "block",
                    float: "right",
                    paddingBottom: "14px",
                    marginTop: "-24px",
                }}
                label="Show archived versions"
                checked={showArchived}
                onClick={() => setShowArchived(!showArchived)}
            />
            <Table>
                <Table.Header className={"sortable-no-border"}>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Base Model</Table.HeaderCell>
                        <Table.HeaderCell>Dataset</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        {/* TODO: Need Loss scores: */}
                        {/* <Table.HeaderCell>Loss</Table.HeaderCell> */}
                        <Table.HeaderCell>Duration</Table.HeaderCell>
                        <Table.HeaderCell>Created</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"center"}>Training Status</Table.HeaderCell>

                        {/* Empty cell for kebab menu: */}
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {/* TODO: Probably need to sort... */}
                    {/* TODO: Remove null when we fix the backend: */}
                    {versions === null || versions.length === 0 ? (
                        <Table.Row>
                            <Table.Cell colSpan={10} textAlign={"center"} verticalAlign={"middle"}>
                                <Divider hidden />
                                <img src={"/model/emptyRepos.svg"} alt="" />
                                <Header as="h2" size={"medium"} style={{ marginBottom: "0.5rem" }}>
                                    Looks like you don't have any adapters yet!
                                </Header>
                                <Link style={{ fontSize: "0.9em" }} to={`/adapters/create/${repo.uuid}`}>
                                    Train your first adapter version
                                </Link>
                                <Divider hidden />
                            </Table.Cell>
                        </Table.Row>
                    ) : null}
                    {/* TODO: Remove null when we fix the backend: */}
                    {(versions as null | adapterVersionListItem[])?.map(
                        (adapterVersionListItem: adapterVersionListItem) =>
                            (!adapterVersionListItem.archived || showArchived) && (
                                <AdapterVersionListItemTableRow
                                    key={adapterVersionListItem.tag}
                                    repo={repo}
                                    adapterVersionListItem={adapterVersionListItem}
                                    setRetrainVersionNumber={setAdapterRetrainVersionNumber}
                                    setCancelVersionNumber={setAdapterCancelVersionNumber}
                                    setDeleteVersionNumber={setAdapterDeleteVersionNumber}
                                    setPromptVersion={setAdapterPromptVersion}
                                    archivalMutationFn={archivalMutationFn}
                                />
                            ),
                    )}
                </Table.Body>
            </Table>
            <RetrainAdapterModal
                adapterRepo={repo}
                adapterVersionNumber={adapterRetrainVersionNumber}
                open={adapterRetrainVersionNumber !== undefined}
                setOpen={() => setAdapterRetrainVersionNumber(undefined)}
            />
            <CancelFinetuningJobModal
                adapterRepoUuid={repo.uuid}
                adapterVersionNumber={adapterCancelVersionNumber}
                open={adapterCancelVersionNumber !== undefined}
                setOpen={() => setAdapterCancelVersionNumber(undefined)}
            />
            <PromptInstructionsModal
                deployment={compatibleDeployment}
                adapter={selectedAdapter}
                open={adapterPromptVersion !== undefined}
                setOpen={() => setAdapterPromptVersion(undefined)}
                parentStateIsLoading={adapterIsLoading}
            />
            <DeleteAdapterVersionModal
                adapterRepoUuid={repo.uuid}
                adapterVersionNumber={adapterDeleteVersionNumber}
                open={adapterDeleteVersionNumber !== undefined}
                setOpen={() => setAdapterDeleteVersionNumber(undefined)}
            />
        </>
    );
};

export default AdapterVersionsTable;
