import { useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Icon, Loader, Table } from "semantic-ui-react";
import { adapterVersion, finetuningJobStatus, finetuningJobTimeline, repo } from "../../../api_generated";
import LiveTimeAgo from "../../../components/LiveTimeAgo";
import CountUpTimer from "../../../components/unsorted/history/CountUpTimer";
import { Auth0TokenOptions } from "../../../data";
import { formatAcceleratorID } from "../../../deployments/misc/utils";
import { SEMANTIC_GREY, SEMANTIC_OLIVE } from "../../../utils/colors";
import dayjsExtended, { durationAsString, durationCalculation } from "../../../utils/dayjs";
import { updateAdapterVersionDescription } from "../../data";
import EditableDescription from "../../misc/EditableDescription";
import { isTerminalJobStatus } from "../../misc/utils";
import { GET_ADAPTER_REPOS_QUERY_KEY } from "../../query";
import FinetuningJobProgressView from "./TrainingJobTimeline";

const OverviewTable = (props: {
    repo: repo;
    adapterVersion: adapterVersion;
    finetuningJobTimeline?: finetuningJobTimeline;
}) => {
    // Parent state:
    const { adapterVersion, finetuningJobTimeline, repo } = props;

    const totalDuration = durationCalculation(adapterVersion.createdAt, adapterVersion?.endedAt);
    let trainingDuration = undefined;
    if (finetuningJobTimeline?.training?.startedAt) {
        // This is a hack to show the duration of the last step
        // TODO: This should be done in the backend
        let finetuningEndedAt = finetuningJobTimeline.training?.endedAt;
        if (!finetuningEndedAt && isTerminalJobStatus(adapterVersion.finetuningJob?.status)) {
            finetuningEndedAt = adapterVersion.endedAt;
        }
        trainingDuration = durationCalculation(finetuningJobTimeline.training.startedAt, finetuningEndedAt);
    }

    return (
        <div style={{ paddingLeft: "20px" }}>
            <Table basic={"very"} collapsing compact>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY }}>Description</Table.Cell>
                        <Table.Cell>
                            <EditableDescription
                                adapterURLPath={undefined}
                                description={adapterVersion.description}
                                inputMinWidth={`${500 / 14}rem`}
                                // Already handling adapterVersion being undefined above
                                updateDescriptionFunction={(text: string, auth0TokenOptions?: Auth0TokenOptions) =>
                                    updateAdapterVersionDescription(
                                        repo.uuid,
                                        adapterVersion!.tag,
                                        text,
                                        auth0TokenOptions,
                                    )
                                }
                                queryKeyToInvalidate={GET_ADAPTER_REPOS_QUERY_KEY()}
                            />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY }}>Created</Table.Cell>
                        <Table.Cell style={{ borderTop: "none" }}>
                            <LiveTimeAgo fromDate={new Date(props.adapterVersion.createdAt)} />
                        </Table.Cell>
                    </Table.Row>
                    {adapterVersion.finetuningJob && (
                        <Table.Row>
                            <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY }}>Total Duration</Table.Cell>
                            <Table.Cell style={{ borderTop: "none" }}>
                                {totalDuration ? (
                                    durationAsString(totalDuration) // If the total duration is available, show that:
                                ) : (
                                    // Otherwise, show a timer:
                                    <CountUpTimer
                                        start={dayjsExtended(new Date(adapterVersion.createdAt))}
                                        color={SEMANTIC_OLIVE}
                                    />
                                )}
                            </Table.Cell>
                        </Table.Row>
                    )}
                    {adapterVersion.finetuningJob && (
                        <Table.Row>
                            <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY }}>
                                Training Duration
                            </Table.Cell>
                            <Table.Cell style={{ borderTop: "none" }}>
                                {trainingDuration ? (
                                    // If the training duration is available, show that:
                                    durationAsString(trainingDuration)
                                ) : // Otherwise, if the job is in a training state, show a timer:
                                adapterVersion.status === finetuningJobStatus.TRAINING ? (
                                    <CountUpTimer
                                        start={dayjsExtended(
                                            new Date(finetuningJobTimeline?.training?.startedAt || ""),
                                        )}
                                        color={SEMANTIC_OLIVE}
                                    />
                                ) : // Otherwise, if the job is not terminal (e.g. pending), show a lone spinner:
                                !isTerminalJobStatus(adapterVersion.finetuningJob.status) ? (
                                    <Loader active inline size="mini" />
                                ) : // And finally, if previous conditions fail then some kind of error has occurred so show nothing:
                                null}
                            </Table.Cell>
                        </Table.Row>
                    )}
                    <Table.Row>
                        <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY }}>Author</Table.Cell>
                        <Table.Cell style={{ borderTop: "none" }}>{adapterVersion.createdBy}</Table.Cell>
                    </Table.Row>
                    {adapterVersion.datasetName && (
                        <Table.Row>
                            <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY }}>Dataset</Table.Cell>
                            <Table.Cell style={{ borderTop: "none" }}>
                                <Link to={`/data/datasets/${adapterVersion.datasetId}`}>
                                    {adapterVersion.datasetName}
                                </Link>
                            </Table.Cell>
                        </Table.Row>
                    )}
                    {adapterVersion.finetuningJob && (
                        <Table.Row>
                            <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY }}>Accelerator</Table.Cell>
                            <Table.Cell style={{ borderTop: "none" }}>
                                {
                                    // If the accelerator is available, show it:
                                    adapterVersion.finetuningJob?.accelerator.id ? (
                                        formatAcceleratorID(adapterVersion.finetuningJob.accelerator.id)
                                    ) : // Otherwise, if the job is not terminal (e.g. pending), show a lone spinner:
                                    !isTerminalJobStatus(adapterVersion.finetuningJob.status) ? (
                                        <Loader active inline size="mini" />
                                    ) : // Otherwise, some kind of error has occurred, so show nothing:
                                    null
                                }
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </div>
    );
};

const AdapterSummary = (props: {
    repo: repo;
    adapterVersion: adapterVersion;
    finetuningJobTimeline?: finetuningJobTimeline;
}) => {
    // Parent state:
    const { repo, adapterVersion, finetuningJobTimeline } = props;

    // UI state:
    const [overviewOpen, setOverviewOpen] = useState(true);
    const [timelineOpen, setTimelineOpen] = useState(
        adapterVersion.finetuningJob?.status !== finetuningJobStatus.COMPLETED,
    );

    return (
        <>
            <Accordion
                styled
                fluid
                className={"black-accordion"}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.03)", marginBottom: "1rem" }}
            >
                <Accordion.Title active={overviewOpen} index={0} onClick={() => setOverviewOpen((x) => !x)}>
                    <Icon name="dropdown" />
                    Overview
                </Accordion.Title>
                <Accordion.Content active={overviewOpen}>
                    <OverviewTable
                        adapterVersion={adapterVersion}
                        repo={repo}
                        finetuningJobTimeline={finetuningJobTimeline}
                    />
                </Accordion.Content>
            </Accordion>
            {adapterVersion.finetuningJob && (
                <Accordion
                    styled
                    fluid
                    className={"black-accordion"}
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.03)" }}
                >
                    <Accordion.Title active={timelineOpen} index={0} onClick={() => setTimelineOpen((x) => !x)}>
                        <Icon name="dropdown" />
                        Training Timeline
                    </Accordion.Title>
                    <Accordion.Content active={timelineOpen}>
                        <FinetuningJobProgressView
                            job={adapterVersion.finetuningJob}
                            timeline={finetuningJobTimeline}
                            open={timelineOpen} // ! NOTE: needed for rerendering hack (see component)
                        />
                    </Accordion.Content>
                </Accordion>
            )}
        </>
    );
};

export default AdapterSummary;
